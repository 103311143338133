<template>
  <div id="assignPermissionModel">
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Update Permissions</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <v-form ref="permission_form" id="kt_permission_form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-select
                    :items="roles_list"
                    v-model="selected_role"
                    label="Role"
                    item-text="name"
                    item-value="id"
                    outlined
                    :rules="[v => !!v || 'Role is required']"
                    dense
                    class="pa-2"
                    id="role_id"
                    @change="getPermissions()"
              ></v-select>
            </v-row>

            <v-row no-gutters :permissions="permissions">
              <v-col cols="12" sm="3" 
              v-for="item in permissions_list" v-bind:key="item.id">
              <template v-if="permissions.includes(item.id)">
                <v-checkbox
                  v-model="item.id"
                  :label="item.name"
                  name="checkedPermission"
                  :value="item.id"
                ></v-checkbox>
              </template>
              <template v-else>
                <v-checkbox
                  :label="item.name"
                  name="checkedPermission"
                  :value="item.id"
                ></v-checkbox>
              </template>
              
              </v-col>
            </v-row> 

            <v-container fluid class="card-footer pt-3">
              <v-btn
                :disabled="!valid"
                color="success"
                id="role_submit"
                class="mr-4"
                @click="validate"
              >
              <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                Update
              </v-btn>
            </v-container>
          </v-form>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_PERMISSIONS } from "@/core/services/store/committee.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "PermissionForm",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      roles_list: [],
      permissions_list: [],
      selected_role:"",
      permissions_ids:[],
      permissions:[],
      valid: true,
      disable_btn: false
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    let context = this;
    context.getRoles();
    context.getAllPermissions();

    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "Permissions" }]);

    const permission_form = KTUtil.getById("kt_permission_form");

    context.fv = formValidation(permission_form, {});
    EventBus.$on("UPDATE_PERMISSIONS", function(payLoad) {
      if (payLoad) {
        Swal.fire("Success", "Permissions updated successfully.", "success");
      } else {
        Swal.fire("Error", "Error in updating permissions.", "error");
      }
    });

  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getRoles() {
      let context = this;
      axios({
        method: "get",
        url: "committee-roles",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.roles_list = result.data.committee_roles;
        }, 
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Roles list",
            "info"
          );
        }
      );
    },
    getAllPermissions(){
      let context = this;
      axios({
        method: "get",
        url: "get-all-permissions",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.permissions_list = result.data.permissions;
          
        }, 
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Roles list",
            "info"
          );
        }
      ); 
    },
    getPermissions(){
      let context = this;
      var role_id = context.selected_role;
      context.permissions = [];
      $('input:checkbox').removeAttr('checked');
      axios({
        method: "get",
        url: "get-role-permissions?role_id="+role_id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.permissions = result.data.permissions;
         
        }, 
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Permissions",
            "info"
          );
        }
      ); 
    },
    updatePermissions() {
      let context = this;
      context.valid = false;
      context.permissions_ids = [];

      $.each($("#assignPermissionModel input[name='checkedPermission']:checked"), function () {
            context.permissions_ids.push($(this).val());
      });     
       
      this.$store.dispatch(UPDATE_PERMISSIONS, {
        role_id : context.selected_role, 
        permissions_ids : context.permissions_ids
      }).then(() => {
        context.disable_btn = false;
      });
      context.valid = true;
    },
    validate() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.permission_form.validate()) {
        context.disable_btn = true;
        this.updatePermissions(); 
      } else {
        this.snackbar = true;
      }
    }
  }
};
</script>
